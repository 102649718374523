import { Box } from 'Components'
import React,{useEffect,useState} from 'react'
import StartModule from './components/StartModule'
import { getFlowById } from '../modules/flows/core/_requests'
import { useParams } from 'react-router'
import { useQuery } from '@tanstack/react-query'
import '../index.css'
import InfoDisclaimer from './InfoDisclaimer/InfoDisclaimer'
import InfoDisclaimerTwo from './InfoDisclaimer/InfoDisclaimerTwo'
import InfoDisclaimerSix from './InfoDisclaimer/InfoDisclaimerSix'
import colores from './gradient-background.png'
import { getAllProduct } from 'modules/flows/products/request'
import Shopping from './InfoDisclaimer/Shopping'

const FormBuilder = () => {
	const { id } = useParams()
	const { data } = useQuery(['listFlows'], () => getFlowById(id ? id : ''))
	const [showGif, setShowGif] = useState(true);
	const [InfoDisclaimerStep, setInfoDisclaimerStep] = useState(1);
	const { data: products, isLoading: isLoadingProducts, isSuccess } = useQuery(['getAllproasdduct'], () => getAllProduct('limit=1000&page=1&name='))

	if (isLoadingProducts && !showGif) {
		return (<>Cargando Productos...</>)
	}

	if (isSuccess) {
		localStorage.setItem('products', JSON.stringify(products))
	}

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowGif(false);
		}, 6000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			{showGif ? <>
				<Box className='gif-container' />
			</> : <>
				<div style={{ width: '100%', height: '30px', marginBottom: '10px', backgroundImage: `url(${colores})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
				/* src={colores} */
				>
					<h1 style={{ color: 'white', fontSize: '1rem' }}>
						Immiland lo tiene todo
					</h1>
				</div>
				<div
					className='form-builder'
					style={{
						margin: '0 auto',
						boxShadow:
							'rgb(255 255 255 / 16%) 0px 10px 36px 0px, rgb(255 255 255 / 6%) 0px 0px 0px 1px ',
						minHeight: '31rem'
					}}
				>
					<div>
						<h1
							style={{
								fontSize: '30px',
								textAlign: 'center',

							}}
						>
							{data?.id === 'ae272be0-dad9-4253-9f52-7efd444e203d' &&
							(<div style={{ fontFamily: 'Lato, sans-serif' }}>Educación Immiland</div>)}
						</h1>
					</div>
					<div>
						{InfoDisclaimerStep == 1 ? <> <InfoDisclaimer setInfoDisclaimerStep={setInfoDisclaimerStep} />  </> : null}
						{InfoDisclaimerStep == 2 ? <> <InfoDisclaimerTwo setInfoDisclaimerStep={setInfoDisclaimerStep} />  </> : null}
						{InfoDisclaimerStep == 5 ? <> {data && <StartModule codeData={data.data} nodes={data.nodes} />}   </> : null}
						{InfoDisclaimerStep == 10 ? <> {data && <Shopping setInfoDisclaimerStep={setInfoDisclaimerStep} />}   </> : null}
						{InfoDisclaimerStep == 6 ? <> {data && <InfoDisclaimerSix setInfoDisclaimerStep={setInfoDisclaimerStep} />}   </> : null}
					</div>
				</div>
			</>}
		</>
	)
}

export default FormBuilder
