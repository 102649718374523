/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box,
	Input,
	Select,
} from 'Components'
import { FamilyTypes, IFamilys } from '../../types'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Button } from 'Components'
import { CountrySelector } from 'Components'
import { ETA } from '../../Data/ETACountries'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import moment from 'moment'
import '../../../../../Components/Modal/modal.css'
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/index.css'
import MyComponent from '../student-visa/DocusignCheckEnvelopeId'
import DateOfBirth from './CustomComponents/DateOfBirth'
import { CivilStatusSelector } from 'Components/CivilStatusSelector'
import { observer } from 'mobx-react'
import { jsonArray, keys } from '../../ContratationAnswers'
import { nanoid } from '@reduxjs/toolkit'
import { Checkbox, Switch } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'

interface IProps {
	closeModal: () => void
	edit: boolean
	step: number
	setStep: Dispatch<SetStateAction<number>>
}

const PersonalDataWorkInsideCanada: React.FC<IProps> = observer(({ closeModal, edit, setStep, step }) => {
	const rootStore = useContext(RootStoreContext)
	const { principalUsed, currentMember, handleChangeFamilyMember, getVisaTypeFromSurvey, familyMembers, setMembersErrors, membersErrors, setFamilyMember, displayErrorGlobal, setDisplayErrorGlobal, setCurrentMember, familygroup } = rootStore.familyStore
	const [documentname, setdocumentname] = React.useState<string[]>([])
	const [birthday, setBirthday] = React.useState(currentMember.birthday)
	const [showBiometricSelect, setShowBiometricSelect] = React.useState(false)
	const [errors, setErrors] = React.useState<string[]>([])
	const [showStudyQuesitons, setShowStudyQuesitons] = React.useState(true)
	const [showCAQ, setShowCAQ] = React.useState(false)
	const [emailForUploaders, setEmailForUploaders] = React.useState('')
	const [verificationDocusign, setverificationDocusign] = React.useState(false)
	const [activateDocument, setActivateDocument] = React.useState<string>("")
	const [documentbiometricsname, setdocumentbiometricsname] = useState('')
	const [displayErrors, setDisplayErrors] = useState(displayErrorGlobal)
	const [errorDate, setErrorDate] = useState(false)
	const [ageForBiometrics, setAgeForBiometrics] = useState(false)
	const [showWarning, setShowWarning] = useState(false);
	const [checkboxChecked, setCheckboxChecked] = useState(false);
	const popupSubmittionLetter = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<span style="color: black; margin-bottom: 30px;">Immiland recomienda altamente en estos casos incluir un submission letter para contrarestar las razones del rechazo previo. Por lo tanto, este item será incluido en su contrato.</span><br></b>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}
	const indexFirstConyugue = familyMembers.findIndex((member) => member.type === FamilyTypes.conyuge);
	let indexChildren = 0
	const emailRegex = new RegExp('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')
	const regex = new RegExp('^[a-zA-Z0-9\\s]+$')
	const { increaseEstep, step: stepLine } = rootStore.formBuilderStore
	const handleChangeMember = (id: any) => {
		const newCurrentMember = familyMembers.find((member) => member.id === id);
		if (newCurrentMember) {
			setCurrentMember(newCurrentMember);
		}
	}

	useEffect(() => { }, [currentMember])
	useEffect(() => {
		if (currentMember.age > -1) handleChange('valid', true)
		handleChange('age', moment().diff(birthday, 'years', false))
	}, [birthday])
	useEffect(() => {
	}, [emailForUploaders])
	useEffect(() => {
		validateShowBiometrics()
		validateShowCAQ()
	}, [currentMember.age, edit])
	useEffect(() => { }, [open])
	useEffect(() => { }, [principalUsed])
	useEffect(() => {
		validateActiveDocument("biometrics")
		validateActiveDocument("hasLoa")
		validateActiveDocument("academicRecord")
		validateActiveDocument("statusEvidence")
	}, [activateDocument])
	useEffect(() => {
		if (documentbiometricsname === '') { setdocumentbiometricsname(documentname[0]) }
	}, [documentname.length]);

	const validateActiveDocument = (documenttype: string) => {
		if (documenttype === "biometrics") {
			return currentMember.documents[0].active
		}
		if (documenttype === "hasLoa") {
			return currentMember.documents[1].active
		}
		else if (documenttype === "statusEvidence") {
			return currentMember.documents[3].active
		}
		else if (documenttype === "academicRecord") {
			return currentMember.documents[4].active
		}
		else {
			return undefined
		}
	}
	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
		const newFamily = Object.assign(currentMember, { [target]: value })
		handleChangeFamilyMember(newFamily)
	}
	const validateShowBiometrics = () => {
		setShowBiometricSelect(true)
		if (
			currentMember.type === FamilyTypes.principal &&
			currentMember.ageForBiometrics === true &&
			getVisaTypeFromSurvey() === 'visadeestudios'
		) {
			setShowBiometricSelect(true)
			return
		}
		if (
			!(currentMember.nationality.toLocaleLowerCase() === 'brazil') &&
			currentMember.etaCountry === true &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.ageForBiometrics === false) {
			setShowBiometricSelect(false)
			return
		}
		if (currentMember.etaRejection === 'si') {
			if (currentMember.ageForBiometrics === true) {
				setShowBiometricSelect(true)
			}
			return
		}
		if (currentMember.etaImmiland === 'no') {
			setShowBiometricSelect(false)
			handleChange('hasBiometrics', false)
			return
		} else if (
			currentMember.nationality.toLocaleLowerCase() === 'brazil' &&
			currentMember.etaImmiland === 'si' &&
			currentMember.visaEU === 'si' &&
			currentMember.etaRejection === 'no'
		) {
			setShowBiometricSelect(false)
			handleChange('hasBiometrics', false)
			return
		}
	}
	const validateShowCAQ = () => {
		if (
			currentMember.quebec === 'si' ||
			(principalUsed()?.quebec == 'si' &&
				currentMember.type === FamilyTypes.children &&
				currentMember.age >= 5 &&
				currentMember.age <= 17)
		) {
			setShowCAQ(true)
			currentMember.validateCAQquestions = true
			setShowStudyQuesitons(true)
		} else if (
			currentMember.quebec === 'no' ||
			principalUsed()?.quebec == 'no' ||
			currentMember.type != FamilyTypes.children ||
			(currentMember.age < 5 && currentMember.age > 17)
		) {
			setShowCAQ(false)
			currentMember.validateCAQquestions = false
		}
		validateNormalVisitorVisaLogic()
	}
	const validateNormalVisitorVisaLogic = () => {
		if (
			currentMember.type === FamilyTypes.grandchildren ||
			currentMember.type === FamilyTypes.otros
		) {
			handleChange('normalVisitorVisaLogic', true)
			return
		}
		if (
			(principalUsed()?.principalHasPathway === 'si' &&
				currentMember.type === FamilyTypes.conyuge) ||
			(currentMember.type === FamilyTypes.children && currentMember.age < 5)
		) {
			handleChange('normalVisitorVisaLogic', true)
		} else {
			handleChange('normalVisitorVisaLogic', false)
		}
	}
	const checkIfAllowSave = () => {
		if (currentMember.isPreviousClient === "si" && currentMember.validContract !== true && currentMember.type === FamilyTypes.principal) {
			console.log('no pasa')
			return false
		}
		if (new Date() < currentMember.birthday) {
			console.log('no pasa')
			return false
		}
		if (currentMember.email != currentMember.confirmEmail) {
			console.log('no pasa')
			return false
		}
		if (currentMember.appliedBefore === "si" && ((!currentMember.validBiometrics && currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !currentMember.isPreviousClient)) {
			if (currentMember.type === FamilyTypes.principal) {
				console.log('no pasa')
				return false
			}
			if (currentMember.type === FamilyTypes.conyuge && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
				console.log('no pasa')
				return false
			}

			if (currentMember.type === FamilyTypes.children && ETA.find(
				(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
				console.log('no pasa')
				return false
			}
		}
		if (currentMember.wantsDependantChildren === "si" && !currentMember.howManyDependantChild) {
			console.log('no pasa')
			return false
		}
		if (currentMember.wantsDependantChildren === "si" && currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0) {
			console.log('no pasa')
			return false
		}
		if (((currentMember.appliedBefore === "si"
			&& ageForBiometrics === true
			&& currentMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
			&& currentMember.type === FamilyTypes.principal)
			||
			((currentMember.type === FamilyTypes.conyuge
				|| currentMember.type === FamilyTypes.children)
				&&
				(ageForBiometrics === true)
				&&
				(ETA.find((etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
			))) {
			if (!currentMember.validBiometrics) {
				console.log('no pasa')
				return false
			}
		}
		if (familyMembers[0].wantsDependantChildren === "si" && (!familyMembers[0].howManyDependantChild || familyMembers[0].howManyDependantChild < 0)) {
			console.log('no pasa')
			return false
		}
		if (!currentMember.email && currentMember.type === FamilyTypes.principal) {
			console.log('no pasa')
			return false
		}
		if (!currentMember.confirmEmail && currentMember.type === FamilyTypes.principal) {
			console.log('no pasa')
			return false
		}
		if (currentMember.type !== 'Conyuge o Pareja de hecho' && !currentMember.civilStatus) {
			console.log('no pasa')
			return false
		}
		if (currentMember.type === 'Solicitante principal' && !currentMember.phoneNumber) {
			console.log('no pasa')
			return false
		}
		if (currentMember.type === 'Solicitante principal' && !currentMember.countryOfCurrentResidency) {
			console.log('no pasa')
			return false
		}
		if (currentMember.type === 'Solicitante principal' && !currentMember.address) {
			console.log('no pasa')
			return false
		}

		if (!currentMember.name || !currentMember.lastname || !currentMember.birthdayFormatted) {
			console.log('no pasa')
			return false
		} else {
			return true
		}
	}
	const checkErrorFamily = () => {
		const familyErrorInfo: any = []
		familyMembers.map((familyMember: any) => {
			let counter = 0
			if (familyMember.isPreviousClient === "si" && familyMember.validContract !== true && familyMember.type === FamilyTypes.principal) {
				counter++
				console.log('no pasa')
			}
			if (new Date() < familyMember.birthday) {
				counter++
				console.log('no pasa')
			}
			if (familyMember.email != familyMember.confirmEmail) {
				counter++
				console.log('no pasa')
			}
			if (familyMember.appliedBefore === "si" && ((!familyMember.validBiometrics && familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA" && ageForBiometrics === true) || !familyMember.isPreviousClient)) {
				if (familyMember.type === FamilyTypes.principal) {
					counter++
					console.log('no pasa')
				}
				if (familyMember.type === FamilyTypes.conyuge && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					counter++
					console.log('no pasa')
				}
				if (familyMember.type === FamilyTypes.children && ETA.find(
					(etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
					counter++
					console.log('no pasa')
				}
			}
			if (familyMember.wantsDependantChildren === "si" && !familyMember.howManyDependantChild) {
				counter++
				console.log('no pasa')
			}
			if (familyMember.wantsDependantChildren === "si" && familyMember.howManyDependantChild && familyMember.howManyDependantChild < 0) {
				counter++
				console.log('no pasa')
			}
			if (((familyMember.appliedBefore === "si"
				&& ageForBiometrics === true
				&& familyMember.typeOfApplication !== "Electronic Travel Authorization - eTA"
				&& familyMember.type === FamilyTypes.principal)
				||
				((familyMember.type === FamilyTypes.conyuge
					|| familyMember.type === FamilyTypes.children)
					&&
					(ageForBiometrics === true)
					&&
					(ETA.find((etas) => etas.country.toLocaleLowerCase() === familyMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)
				))) {
				if (!familyMember.validBiometrics) {
					counter++
					console.log('no pasa')
				}
			}
			if (familyMembers[0].wantsDependantChildren === "si" && (!familyMembers[0].howManyDependantChild || familyMembers[0].howManyDependantChild < 0)) {
				counter++
				console.log('no pasa')
			}
			if (!familyMember.email && familyMember.type === FamilyTypes.principal) {
				counter++
				console.log('no pasa')
			}
			if (!familyMember.confirmEmail && familyMember.type === FamilyTypes.principal) {
				counter++
				console.log('no pasa')
			}
			if (familyMember.type !== 'Conyuge o Pareja de hecho' && !familyMember.civilStatus) {
				counter++
				console.log('no pasa')
			}
			if (familyMember.type === 'Solicitante principal' && !familyMember.phoneNumber) {
				counter++
				console.log('no pasa')
			}
			if (familyMember.type === 'Solicitante principal' && !familyMember.countryOfCurrentResidency) {
				counter++
				console.log('no pasa')
			}
			if (familyMember.type === 'Solicitante principal' && !familyMember.address) {
				counter++
				console.log('no pasa')
			}
			if (!familyMember.name) {
				counter++
				console.log('no pasa')
			}
			if (!familyMember.lastname || !familyMember.birthdayFormatted) {
				counter++
				console.log('no pasa')
			}
			if (!familyMember.birthdayFormatted) {
				counter++
				console.log('no pasa')
			}
			if (!familyMember.passportCountry) {
				counter++
				console.log('no pasa')
			}
			/* if (!familyMember.appliedBefore) {
				counter++
				console.log('no pasa')
			} */
			familyErrorInfo.push({ id: familyMember.id, errors: counter })
		})
		setMembersErrors(familyErrorInfo)
		return familyErrorInfo
	}
	const formPersonal = () => {
		return (
			<div className='flex flex-wrap justify-center '>
				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Tipo de solicitud</Label>
						<Label style={{ color: '#22AD5C' }} >{currentMember.typeOfApplication}</Label>
					</BoxModal>}
				<BoxModal>
					<Label>Nombres: (Como aparece en su pasaporte)</Label>
					<Input
						name='Nombres'
						className='custom-input'
						label='Nombres'
						placeholder='Nombres'
						type='text'
						value={currentMember.name}
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('name', e.target.value) : handleChange('name', undefined) }
						}}
						size='small' />
					{!currentMember.name && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Los nombres son obligatorios</span>}
				</BoxModal>
				<BoxModal>
					<Label>Apellidos: (Como aparece en su pasaporte) </Label>
					<Input
						name='Apellidos'
						className='custom-input'
						label='Apellidos'
						placeholder='Apellidos'
						type='text'
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('lastname', e.target.value) : handleChange('lastname', undefined) }
						}}
						value={currentMember.lastname}
						size='small' />
					{!currentMember.lastname && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Los apellidos son obligatorios
						</span>}
				</BoxModal>
				{currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<Label>Email</Label>
						<Input
							className='custom-input'
							label='Email'
							defaultValues={currentMember.email}
							placeholder='Email'
							type='email'
							id='email'
							name='Email'
							onChange={(e: any) => {
								{ e.target.value != '' && e.target.value.match(emailRegex) ? handleChange('email', e.target.value) /* setEmailForUploaders(e.target.value) */ : handleChange('email', undefined) }
							}}
							value={currentMember.email}
							size='small'
							required={true} />
						{!currentMember.email && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su email y revise que este escrito de forma correta</span>}
					</BoxModal>}
				{currentMember.type === FamilyTypes.principal &&
					<BoxModal>
						<Label>Confirmar Email</Label>
						<div className='relative'>
							<Input
								className='custom-input'
								label='Email'
								defaultValues={currentMember.confirmEmail}
								placeholder='Email'
								type='email'
								id='email'
								name='email'
								value={currentMember.confirmEmail}
								onChange={(e: any) => {
									{ e.target.value != '' && e.target.value.match(emailRegex) ? handleChange('confirmEmail', e.target.value) : handleChange('confirmEmail', undefined) }
									{ e.target.value != '' && localStorage.setItem("email", e.target.value) }
								}}
								size='small'
								required={true} />
						</div>
						{(!currentMember.confirmEmail || currentMember.confirmEmail !== currentMember.email) && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>El campo escrito en " email" y en "confirmar email" deben ser iguales</span>}
					</BoxModal>}
				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Número de telefono : (+código del país) + (000-000-0000)</Label>
						<Input
							name='Numero de telefono'
							defaultValues={currentMember.phoneNumber}
							className='custom-input'
							label='Numero de telefono'
							placeholder='Numero de telefono'
							type='text'
							value={currentMember.phoneNumber}
							onChange={(e: any) => handleChange('phoneNumber', e.target.value)}
							size='small' />
						{!currentMember.phoneNumber && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe escribir su numero de telefono </span>}
					</BoxModal>}
				<DateOfBirth displayErrors={displayErrors} errorDate={errorDate} setErrorDate={setErrorDate} setAgeForBiometrics={setAgeForBiometrics} edit={edit} />
				<BoxModal>
					<Label>País emisor de su pasaporte</Label>
					<div style={{ color: '#22AD5C' }} className='relative text-tomato-500 font-bold'>
						{currentMember.type === FamilyTypes.principal ? <>{currentMember.passportCountry}</> : <CountrySelector
							handleChange={(e: any) => {
								if (ETA.find(
									(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined) {
									if (e.target.value !== '') {
										handleChange('passportCountry', e.target.value)
									} else {
										handleChange('passportCountry', undefined)
									}
								}
							}}
							value={currentMember.passportCountry || ''}
							label='Pais' />}
					</div>
					{!currentMember.countryOfCitizenship && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar pais de ciudadanía
						</span>}
				</BoxModal>
				{currentMember.type === 'Solicitante principal' &&
					<BoxModal>
						<Label>Dirección</Label>
						<Input
							name='Direccion'
							defaultValues={currentMember.address}
							className='custom-input'
							label='Direccion'
							placeholder='Direccion'
							type='text'
							value={currentMember.address}
							onChange={(e: any) => { e.target.value != '' && e.target.value.match(regex) ? handleChange('address', e.target.value) : handleChange('address', undefined) }}
							size='small' />
						{!currentMember.address && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
								Debe indicar su dirección. Este campo no puede tener Caracteres especiales(#~€$%).
							</span>}
					</BoxModal>}
				<BoxModal>
					<Label>Estado Civil</Label>
					<div className='relative'>
						{(currentMember.type !== 'Conyuge o Pareja de hecho') ?
							<CivilStatusSelector
								handleChange={(e: any) => {
									{
										e.target.value != '' ? handleChange('civilStatus', e.target.value) :
											handleChange('civilStatus', undefined)
									}
									if (e.target.value === "Casado" || e.target.value === "Unión marital de hecho") {
										console.log('')
									} else {
										handleChange('wantsAddConyugue', 'no')
										handleChange('ApplyWithSpouse', 'no')
									}
								}}
								value={currentMember.civilStatus || ''}
								label='Pais'
							/> :
							<p style={{ color: '#22AD5C' }}>{principalUsed()?.civilStatus}</p>}
					</div>
					{(!currentMember.civilStatus && (currentMember.type === FamilyTypes.principal || currentMember.type === FamilyTypes.children)) && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar su estado civil
						</span>}
					{currentMember.type === 'Solicitante principal' &&
						familyMembers[0].typeOfApplication !== 'Super visa' &&
						familyMembers[0].typeOfApplication !== 'Permiso de trabajo Trabajador Vulnerable' &&
						<>
							{
								(familyMembers[0].civilStatus === "Casado" ||
									familyMembers[0].civilStatus === "Unión marital de hecho") &&
									currentMember.typeOfWorkPermit !== 'Permiso de trabajo ABIERTO para parejas de un estudiante o trabajador que ya está en Canadá' ?
									<BoxModal>
										<Switch
											className="custom-switch"
											checked={familyMembers[0].wantsAddConyugue === "si"}
											onChange={(checked) => {
												if (checked === true) {
													familyMembers[0].wantsAddConyugue = "si";
													familyMembers[0].ApplyWithSpouse = 'si'
													const newMember = {
														id: nanoid(),
														name: '',
														type: FamilyTypes.conyuge,
														lastname: '',
														nationality: '',
														age: -1,
														hasBiometrics: undefined,
														birthday: new Date(),
														birthdayFormatted: moment().format('YYYY-MM-DD'),
														visaType: '',
														groupId: familygroup[0].id,
														monthDifference: -1,
														documents: [
															{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }
														]
													};
													setFamilyMember([...familyMembers, newMember]);
												} else {
													familyMembers[0].wantsAddConyugue = "no";
													familyMembers[0].ApplyWithSpouse = 'no'
													const filteredFamilyMembers = familyMembers.filter((member) => member.type !== FamilyTypes.conyuge);
													setFamilyMember(filteredFamilyMembers);
													setCurrentMember(familyMembers[0]);
												}
											}}
										/>
										<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR CONYUGUE</label>
									</BoxModal> :
									<>
										<BoxModal>
											<Switch
												disabled
												className="custom-switch"
												style={{ backgroundColor: 'gray' }}
											/>
											<label className="inline-block pl-[0.15rem] hover:cursor-pointer">AGREGAR CONYUGUE</label>
										</BoxModal>
									</>
							}
							<BoxModal>
								<div className='flex flex-row gap-4 items-center'>
									<div className='flex flex-row gap-2'>
										<Switch
											checked={familyMembers[0].wantsDependantChildren === "si"}
											className="custom-switch"
											onChange={(checked: boolean) => {
												if (checked === true) {
													familyMembers[0].wantsDependantChildren = "si";
												} else {
													familyMembers[0].wantsDependantChildren = "no";
												}
												if (!checked) {
													familyMembers[0].howManyDependantChild = undefined;
													const filteredFamilyMembers = familyMembers.filter(
														(member) => member.type !== FamilyTypes.children
													);
													setFamilyMember(filteredFamilyMembers);
													setCurrentMember(familyMembers[0]);
												}
											}}
										/>
										<label className="inline-block pl-[0.15rem] hover:cursor-pointer">
											AGREGAR HIJOS
										</label>
									</div>
									{familyMembers[0].wantsDependantChildren === "si" &&
										<Input
											style={{ width: '4rem', height: '30px' }}
											className='custom-input no-spinner'
											label='Numero de Hijos dependientes'
											placeholder='0'
											type='number'
											inputMode='numeric'
											min={1}
											value={familyMembers[0].howManyDependantChild}
											onKeyDown={(e: any) => {
												if (e.key === "ArrowUp" || e.key === "ArrowDown") {
													e.preventDefault();
												}
											}}
											onChange={(e: any) => {
												if (e.target.value > -1 && e.target.value < 10) {
													familyMembers[0].howManyDependantChild = e.target.value
													/* { e.target.value > 0 ? handleChange('howManyDependantChild', e.target.value) : handleChange('howManyDependantChild', undefined) } */
													{
														e.target.value != '' &&
															e.target.value > 0 &&
															localStorage.setItem("wantsDependantChildren", e.target.value);
													}
													// Use the functional form of setState to update familyMembers
													if (e.target.value === '') {
														const filteredFamilyMembers = familyMembers.filter(member => member.type !== FamilyTypes.children);
														setFamilyMember(filteredFamilyMembers)
														setCurrentMember(familyMembers[0])
														return
													}
													const updatedFamilyMembers = [...familyMembers];
													for (let i = 0; i < e.target.value; i++) {
														const newMember: IFamilys = {
															id: nanoid(),
															name: '',
															type: FamilyTypes.children,
															lastname: '',
															nationality: '',
															age: -1,
															hasBiometrics: undefined,
															birthday: new Date(),
															birthdayFormatted: moment().format('YYYY-MM-DD'),
															visaType: '',
															groupId: familygroup[0].id,
															monthDifference: -1,
															documents: [{ name: "biometrics", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "hasLoa", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "previousPermit", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "statusEvidence", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "academicRecord", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' },
															{ name: "coopLetter", maxFiles: 0, description: "", active: true, nameOfTheDocumentSubmitted: '' }]
														}
														updatedFamilyMembers.push(newMember);
													}
													setFamilyMember(updatedFamilyMembers)
												}
											}}
											size='small'
										/>
									}
								</div>
								{currentMember.wantsDependantChildren === "si" && ((currentMember.howManyDependantChild && currentMember.howManyDependantChild < 0) || (!currentMember.howManyDependantChild)) && displayErrors === true &&
									<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe indicar si desea agregar a hijos dependientes</span>}
							</BoxModal>
						</>}
				</BoxModal>
				{currentMember.type === FamilyTypes.principal && <BoxModal>
					<Label>
						¿Cual es su estatus migratorio en Canadá?
					</Label>
					<Select
						name='¿Cual es su estatus migratorio en Canadá?'
						defaultValue={currentMember.migratoryStatus}
						className='custom-input'
						labelId='demo-simple-select-label'
						label='¿Cual es su estatus migratorio en Canadá?'
						value={currentMember.migratoryStatus}
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('migratoryStatus', e.target.value) : handleChange('migratoryStatus', undefined) }
							if (e.target.value === "visitante" || e.target.value === "estudiante" || e.target.value === "trabajador") {
								currentMember.documents[2].active = true
							} else {
								currentMember.documents[2].active = false
							}
						}}
					>
						<option value=''>Seleccione</option>
						<option value='visitante'>Visitante</option>
						<option value='estudiante'>Estudiante</option>
						<option value='trabajador'>Trabajador</option>
					</Select>
					{!currentMember.migratoryStatus && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe indicar su estatus migratorio actual</span>}
				</BoxModal>}
				{currentMember.ageForBiometrics === true &&
					<BoxModal>
						<Label >
							¿Tiene biométricos válidos?
						</Label>
						<Select
							name='tieneBiometricos'
							defaultValue={currentMember.validBiometrics}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Tiene biometricos'
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('validBiometrics', e.target.value) : handleChange('validBiometrics', undefined) }
								if (e.target.value === "si") {
									currentMember.documents[0].active = true
								} else {
									currentMember.documents[0].active = false
								}
							}}
							value={currentMember.validBiometrics}>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>

						{!currentMember.validBiometrics && displayErrors === true &&
							<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si tiene biometricos</span>}
					</BoxModal>}

					{currentMember.typeOfWorkPermit !== "Postulación a Working Holiday" && currentMember.typeOfWorkPermit !== "Postulación a Young Professionals" && currentMember.typeOfWorkPermit !== "Postulación a International CoOp" /* && currentMember.appliedBefore === "si"  */&& currentMember.type === FamilyTypes.principal && principalUsed()?.typeOfApplication !== 'Co-op work permit' && (
          <BoxModal>
            <Label>
              ¿Ha tenido rechazos previos con Canadá del mismo tipo de solicitud a la que desea aplicar?
            </Label>
            <Select
              name='Alguna vez le ha sido negada una solicitud por el Gobierno de Canadá'
              defaultValue={currentMember.canadianVisaRejection}
              className='custom-input'
              labelId='demo-simple-select-label'
              label='Alguna vez le ha sido negada una solicitud por el Gobierno de Canadá'
              value={currentMember.canadianVisaRejection}
              onChange={(e: any) => {
                handleChange('canadianVisaRejection', e.target.value);
                if (e.target.value === 'si') {
                  setShowWarning(true);
                  if (principalUsed()?.typeOfApplication !== 'Electronic Travel Authorization - eTA') {
                  /*   popupSubmittionLetter(); */
                  }
                } else {
                  setShowWarning(false);
                }
              }}
            >
              <option value=''>Seleccione</option>
              <option value='si'>Si</option>
              <option value='no'>No</option>
            </Select>
            {!currentMember.canadianVisaRejection &&
              currentMember.type === FamilyTypes.principal &&
              displayErrors === true && (
                <span
                  className='text-tomato-500 text-xs italic'
                  style={{ marginTop: '-11px', marginBottom: '11px' }}
                >
                  Debe responder si alguna vez le ha sido negada una solicitud por el gobierno de Canadá
                </span>
              )}

            {/* Mostrar el div solo si la respuesta es 'si' */}
            {showWarning && principalUsed()?.typeOfApplication !== "Electronic Travel Authorization - eTA" && currentMember.typeOfClosedWorkPermit !== 'International Experience Canada work permit' && currentMember.purposeVisit !== "Residencia Permanente" && (
              <div style={{ height: '16rem', width: '46rem', position: 'static', marginTop:'2rem' }} className='rejected-doc-yellow-atip mt-2 mb-2'>
                <div className='flex flex-row items-start gap-4 items-center' style={{ width: '100%' }}>
                  <div className='flex flex-col' style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                      <img src='/icons/warning.svg' alt='WarningIcon' style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                      <div style={{ gap: '5px', display: 'flex', flexDirection: 'column' }}>
                        <p style={{ color: '#9D5425', fontSize: '13.8px', margin: '0px', fontWeight: '700' }}>
                          <b>Evite nuevos rechazos añadiendo Servicios de Atip</b>
                        </p>
                        <p style={{ color: '#9D5425', fontSize: '14px', margin: '0px', fontWeight: '400' }}>
                          <a>Obtenga detalles específicos del rechazo de su visa, más allá de las razones genéricas. <br />	
                          Con las notas del oficial, identificaremos los elementos que causaron el rechazo y le ofreceremos estrategias precisas para aumentar sus probabilidades de aprobación.</a>
                          <br />	<b style={{ fontWeight: '600', fontSize: '13.8px' }}>Se añadirá un cargo de $407 CAD</b> <a style={{ fontWeight: '400' }}>a su cotización</a>
                        </p>
                      </div>
                    </div>

                    <div style={{ backgroundColor: '#F9FAFB', width: '100%', borderRadius: '10px', marginTop: '20px' }}>
                      <div style={{ fontSize: '1.1rem', margin: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                        <Checkbox
                          checked={checkboxChecked}
                          onChange={(e) => {
                            setCheckboxChecked(e.target.checked);
                            currentMember.wantsAtip = e.target.checked;
                            console.log('wantsAtip:', currentMember.wantsAtip);
                          }}
                        />
                        <b style={{ fontSize: '16px', fontWeight: '400', color: 'black' }}>Deseo añadir el servicio de ATIP</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </BoxModal>
        )}
				{(currentMember.type === FamilyTypes.principal ||
					currentMember.type === FamilyTypes.conyuge ||
					(currentMember.type === FamilyTypes.children && currentMember.ageForVisitorDependant === false)
					&& (ETA.find(
						(etas) => etas.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) === undefined)) &&
					<BoxModal>
						<Label>Si se aprueba su solicitud IRCC no emitirá automáticamente una visa de residente temporal (TRV) ¿Desea incluir este servicio?</Label>
						<Select
							name='Su carta de aceptación esta condicionada por un requisito lingüístico'
							defaultValue={currentMember.wantsTemporaryResidenceService}
							className='custom-input'
							labelId='demo-simple-select-label'
							label='Seguro privado'
							onChange={(e: any) => {
								{ e.target.value != '' ? handleChange('wantsTemporaryResidenceService', e.target.value) : handleChange('wantsTemporaryResidenceService', undefined) }
							}}
						>
							<option value=''>Seleccione</option>
							<option value='si'>Si</option>
							<option value='no'>No</option>
						</Select>
						{!currentMember.wantsTemporaryResidenceService && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
							Debe seleccionar desea añadir el servicio de visa de residente temporal para estudiantes o trabajadores en Canadá (documento de viaje)
						</span>}
					</BoxModal>}
				{(currentMember.typeOfWorkPermit === 'Permiso de trabajo CERRADO con oferta de trabajo de un empleador canadiense') ?
					<>
						<BoxModal>
							<Label>Nombre completo del empleador</Label>
							<Input
								name='Nombre del empleador'
								className='custom-input'
								label='Nombre del empleador'
								placeholder='Nombre del empleador'
								type='text'
								onChange={(e: any) => {
									{ e.target.value != '' ? handleChange('employerName', e.target.value) : handleChange('employerName', undefined) }
								}}
								value={currentMember.employerName}
								size='small' />
							{!currentMember.employerName && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
									Debe indicar el nombre del empleador
								</span>}
						</BoxModal>
						<BoxModal>
							<Label>Correo electronico de su empleador</Label>
							<Input
								name='Correo electronico de su empleador'
								className='custom-input'
								label='Correo electronico de su empleador'
								placeholder='Correo electronico de su empleador'
								type='text'
								onChange={(e: any) => {
									{ e.target.value != '' ? handleChange('employerEmail', e.target.value) : handleChange('employerEmail', undefined) }
								}}
								value={currentMember.employerEmail}
								size='small' />
							{!currentMember.employerEmail && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
									Debe agregar el email del empleador
								</span>}
						</BoxModal>
						<BoxModal>
							<Label>Nombre legal de la compañia</Label>
							<Input
								name='Nombre legal de la compañia'
								className='custom-input'
								label='Nombre legal de la compañia'
								placeholder='Nombre de la compañia'
								type='text'
								onChange={(e: any) => {
									{ e.target.value != '' ? handleChange('companyName', e.target.value) : handleChange('companyName', undefined) }
								}}
								value={currentMember.companyName}
								size='small' />
							{!currentMember.companyName && displayErrors === true &&
								<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
									Debe agregar el nombre de la compañia
								</span>}
						</BoxModal>
					</> : null}
				{/* boton contrac docusign */}
				{currentMember.isPreviousClient === "si" && <BoxModal className='sm:w-96 px-3 mb-3 md:mb-3 w-full' style={{ marginTop: '12px' }}>
					<MyComponent setVerificationDocusign={setverificationDocusign} />
					{currentMember.isPreviousClient === "si" && !currentMember.validContract && membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>No puede guardar sus datos si su número de contrato no ha sido verificado</span>}
				</BoxModal>}
				{!currentMember.validContract && currentMember.type === FamilyTypes.principal && (currentMember.validContract === undefined || currentMember.validContract === false) &&
					<BoxModal >
						<Label>Código de descuento </Label>
						<Input
							className='custom-input'
							label='Codigo'
							placeholder='Codigo'
							type='text'
							value={currentMember.discountCode}
							onChange={(e: any) => handleChange('discountCode', e.target.value)}
							size='small'
						/>
					</BoxModal>}
				{currentMember.type === FamilyTypes.principal && <BoxModal>
					<p className='text-tomato-500 md:h-4 h8' style={{ fontSize: '1rem', fontWeight: 'bold' }}>¡Obtén un descuento!</p>
					<Label>
						¿Anteriormente ha realizado algún proceso de visado con Immiland?
					</Label>
					<Select
						name='Anteriormente ha realizado algun proceso de visado con Immiland'
						defaultValue={currentMember.isPreviousClient}
						className='custom-input'
						labelId='demo-simple-select-label'
						label='Anteriormente ha realizado algun proceso de visado con Immiland'
						value={currentMember.isPreviousClient}
						onChange={(e: any) => {
							{ e.target.value != '' ? handleChange('isPreviousClient', e.target.value) : handleChange('isPreviousClient', undefined) }
						}}>
						<option value=''>Seleccione</option>
						<option value='si'>Si</option>
						<option value='no'>No</option>
					</Select>
					{!currentMember.isPreviousClient && displayErrors === true &&
						<span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>Debe responder si alguna vez le ha sido negada una solicitud por el gobierno de Canadá</span>}
				</BoxModal>}
			</div>
		)
	}

	const sorted = () => {
		const principalMember = familyMembers.find(member => member.type === FamilyTypes.principal);
		const conyugeMembers = familyMembers.filter(member => member.type === FamilyTypes.conyuge);
		const childrenMembers = familyMembers.filter(member => member.type === FamilyTypes.children);
		const sortedFamilyMembers = [];
		if (principalMember) {
			sortedFamilyMembers.push(principalMember);
		}
		if (conyugeMembers.length > 0) {
			sortedFamilyMembers.push(...conyugeMembers);
		}
		if (childrenMembers.length > 0) {
			sortedFamilyMembers.push(...childrenMembers);
		}
		return sortedFamilyMembers
	}

	return (
		<div className='my-4 h-100'>
			<Box>
				<div className='flex flex-wrap justify-center'>
					<div>
						<form className='w-full' id='fromEl' style={{ fontFamily: 'Lato, sans-serif' }}>
							<div className='mx-16'>
								{sorted() && sorted().length > 0 && sorted().map((member) => {
									{ member.type === FamilyTypes.children && indexChildren++ }
									const memberError = membersErrors.find((error: any) => error.id === member.id);
									if (memberError && memberError.errors !== undefined) {
										return (
											<div>
												<div className='form-tab-continer'>
													{memberError.errors ? <>
														<div key={member.id} className='w-full'>
															<button className='custom-input-error'
																onClick={(e) => {
																	e.preventDefault();
																	handleChangeMember(member.id);
																	if (memberError.errors > 0) {
																		setDisplayErrorGlobal(true);
																	} else {
																		setDisplayErrorGlobal(false);
																	}
																}}>
																<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																<div className='flex flex-row gap-4'>
																	<div className='error-flag-container'>
																		<div className='error-flag-simbol'>
																			<div>x</div>
																		</div>
																		<p> Tiene {memberError.errors} errores</p>
																	</div>
																	<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																</div>
															</button>
														</div>
													</> :
														<>
															<div key={member.id} className='w-full'>
																<button className='custom-input-success'
																	onClick={(e) => {
																		e.preventDefault();
																		handleChangeMember(member.id);
																		if (memberError.errors > 0) {
																			setDisplayErrorGlobal(true);
																		} else {
																			setDisplayErrorGlobal(false);
																		}
																	}}>
																	<p>{member.type}{member.type === FamilyTypes.children && ` #${indexChildren} `}</p>
																	<div className='flex flex-row gap-4'>
																		<div className='success-flag-container'>
																			<div className='success-flag-simbol'>
																				<div>
																					<FontAwesomeIcon style={{ fontSize: '10px', color: 'white' }} icon={faCheck} />
																				</div>
																			</div>
																			<p> Tiene datos correctos</p>
																		</div>
																		<FontAwesomeIcon style={{ fontSize: '24px' }} icon={faChevronDown} />
																	</div>
																</button>
															</div>
														</>}
													{member.id === currentMember.id &&
														formPersonal()}
												</div>
											</div>
										);
									} else {
										return (
											<div>
												<div className='form-tab-continer'>
													<button className='custom-input-button' onClick={(e) => {
														e.preventDefault();
														handleChangeMember(member.id);
													}} key={member.id}>
														{member.type} {member.type === FamilyTypes.children && `#${indexChildren} `}
													</button>
													{member.id === currentMember.id &&
														formPersonal()}
												</div>
											</div>
										)
									}
								})}
							</div>
							{membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0) > 0 ? <div className='errors-main-flag'>
								<div className='error-flag-simbol'>
									<div>x</div>
								</div>
								<div>
									<p style={{ color: '#BC1C21', fontSize: '0.9rem' }}>{`tiene ${membersErrors.reduce((acumulador: any, miembro: any) => acumulador + miembro.errors, 0)} errores en su formulario`}</p>
									<p style={{ color: '#F56060', fontSize: '0.7rem' }}>Por favor, revise cada categoria y corrijalos para poder continuar</p>
								</div>
							</div> : null}

							<div className='flex flex-wrap justify-center '>
								<div className='flex flex-wrap -mx-3 mb-2'>
									<div className='w-full  px-3 mb-6 md:mb-0' style={{ marginTop: '70px' }}>
										{errors.length > 0 ?
											<span
												className='text-tomato-500 italic text-base w-full'
												style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}
											>¡Debe llenar los campos requeridos!</span>
											: ''}
										<div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center', marginTop: '2rem' }}>
											{edit === false ?
												<Button type='button' onClick={() => {
													setCurrentMember(familyMembers[0])
													setStep(10)
												}} className='backbutton-disc2'>
													Atras
												</Button> : null
											}
											<Button type='button' onClick={() => {
												console.log('hola andrea', familyMembers)
												window.scrollTo(0, 0)
												if (checkIfAllowSave() === true && (checkErrorFamily().some((item: any) => item.errors > 0) === false)) {
													jsonArray.length = 0
													handleChange('principal', true)
													handleChange('valid', true)
													for (const familyMem of familyMembers) {
														const answers: any = {
														}
														for (const key in keys) {
															answers[keys[key]] = familyMem[key as keyof typeof familyMem]
														}
														jsonArray.push(answers)
														familyMem.answers = []
														familyMem.answers[0] = answers
													}
													closeModal()
													setDisplayErrors(false)
													increaseEstep()
												} else {
													checkErrorFamily()
													setDisplayErrors(true)
												}
											}} className='startbutton-disc2'>
												Guardar
											</Button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</Box>
		</div>
	)
})

export default PersonalDataWorkInsideCanada

