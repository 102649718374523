/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* stylelint-disable unknown-word */
import {
	Input
} from 'Components'
import { Dispatch, SetStateAction, useEffect, } from 'react'
import { BoxModal } from 'Components/BoxModal'
import { Label } from 'Components/Label'
import { RootStoreContext } from 'stores/rootStore'
import { useContext } from 'react'
import React from 'react'
import moment from 'moment'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2'
import { WorkingHoliday } from 'FormBuilder/components/FamilyModule/Data/WorkingHolidayCountries'
interface IProps {
	displayErrors: boolean
	setErrorDate: Dispatch<SetStateAction<boolean>>
	errorDate: boolean
	setAgeForBiometrics?: Dispatch<SetStateAction<boolean>>
	style?: any
	edit: boolean

}

const DateOfBirth: React.FC<IProps> = ({ displayErrors, setErrorDate, setAgeForBiometrics }) => {
	const rootStore = useContext(RootStoreContext)
	const { principalUsed } = rootStore.familyStore
	const { currentMember, familyMembers } =
		rootStore.familyStore
	const [birthday, setBirthday] = React.useState(() => {
		const savedBirthday = localStorage.getItem("birthday" + currentMember.id);
		return savedBirthday ? new Date(savedBirthday) : null;
	});

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {
	}

	const [age, setAge] = React.useState('');


	const validateAgeForWorkingHoliday = (birthday: any) => {
		const currentDate = moment();
		const ageLimitDateWorkingMin = moment(birthday).add(18, 'years').toDate();
		const ageLimitDateWorkingMax = moment(birthday).add(35, 'years').toDate();
		if (principalUsed()?.purposeVisit !== "Residencia Permanente") {
			if (WorkingHoliday.find((holidays) => holidays.country.toLocaleLowerCase() === currentMember.countryOfCitizenship?.toLocaleLowerCase()) && currentMember.typeOfWorkPermit === 'Postulación a Working Holiday') {
				if (currentDate.isBetween(ageLimitDateWorkingMin, ageLimitDateWorkingMax)) {
					popupWorkingHolidayTrue()
				} else {
					popupWorkingHolidayFalse()
				} popupChildren21
			}
		}
	}

	const validateAgeDependant = (birthday: any) => {
		const currentDate = moment();
		const ageLimitDateWorkingMin = moment(birthday).add(18, 'years').toDate();
		const ageLimitDateWorkingMax = moment(birthday).add(21, 'years').toDate();
		if (principalUsed()?.purposeVisit !== "Residencia Permanente") {
			if (currentMember.type === 'Hija(o) dependiente' && (familyMembers[0].purposeVisit === 'Estudios' || principalUsed()?.typeOfApplication !== 'Visitor Visa Extension')) {
				if (currentDate.isBetween(ageLimitDateWorkingMin, ageLimitDateWorkingMax)) {
					popupChildren20()
				}
				if (currentDate.isAfter(ageLimitDateWorkingMax)) {
					popupChildren21()
				}
			}
		}

		if (currentMember.type === 'Hija(o) dependiente' &&
			currentMember.ageIndependentChildren === true &&
			(currentMember.typeOfApplication === "Electronic Travel Authorization - eTA" ||
				principalUsed()?.purposeVisit === "Residencia Permanente")) {
			const popUpIndependent = Swal.mixin({
				customClass: {
					confirmButton: 'btn btn-success',
					cancelButton: 'btn btn-danger'
				},
				/*  buttonsStyling: true  */
			})
			popUpIndependent.fire({
				html: '<p style="text-align: justify">Los hijos mayores de 21 años y 8 meses, deben realizar una aplicacion aparte como aplicante principal</p>',
				icon: 'warning',
				showCancelButton: true,

				confirmButtonText: '<p style="text-align: justify">  <a style="color: white; text-decoration: underline;"  target="_blank" href="https://flow.immiland.app/flow/354bf321-80ff-44c2-ab1c-f4c17d9eaf95">Comenzar aplicacion aparte de este miembro</p>',
				cancelButtonText: 'Ajustar la edad',
				reverseButtons: true
			})
		}
	}

	const validateAgeForBiometrics = (birthday: any) => {
		const currentDate = moment();
		const ageLimitDateBimetrics = moment(birthday).add(13, 'years').add(8, 'months').toDate();
		const ageLimitSuperiorDateBimetricsMax = moment(birthday).add(79, 'years').add(0, 'months').toDate();
		if (currentDate.isBetween(ageLimitDateBimetrics, ageLimitSuperiorDateBimetricsMax)) {
			currentMember.ageForBiometrics = true
			{ setAgeForBiometrics && setAgeForBiometrics(true) }
		} else {
			currentMember.ageForBiometrics = false
			{ setAgeForBiometrics && setAgeForBiometrics(false) }
		}

		const ageLimitDateVisitorDependant = moment(birthday).add(4, 'years').add(0, 'months').toDate();
		if (currentDate.isSameOrAfter(ageLimitDateVisitorDependant)) {
			currentMember.ageForVisitorDependant = false
		} else {
			currentMember.ageForVisitorDependant = true
		}

		const ageLimitDateCAQMinor = moment(birthday).add(17, 'years').add(6, 'months').toDate();
		if (currentDate.isSameOrAfter(ageLimitDateCAQMinor)) {
			currentMember.ageForCAQMinor = false
		} else {
			currentMember.ageForCAQMinor = true
		}
		const ageIsEqualOrMoreThenTOnePEight = moment(birthday).add(21, 'years').add(8, 'months').toDate();
		if (currentDate.isSameOrAfter(ageIsEqualOrMoreThenTOnePEight)) {
			currentMember.ageIndependentChildren = true
		} else {
			currentMember.ageIndependentChildren = false
		}
	}

	const calculateAge = (birthDate: Date) => {

		const currentDate = moment();
		const age = moment.duration(currentDate.diff(birthDate));
		const ageInYears = age.years();
		currentMember.birthdayFormatted = moment(birthDate).format('YYYY-MM-DD');
		currentMember.age = ageInYears;
		handleChange("age", ageInYears);
		setAge('Edad Actual: ' + ageInYears + ' años ');
		setErrorDate(false);
	};

	useEffect(() => {
		const birthday: string | null = localStorage.getItem("birthday" + currentMember.id);
		if (birthday !== null) {
			if (localStorage.getItem("birthday" + currentMember.id) !== null) {
				calculateAge(new Date(birthday))
			}
		}
	}, [birthday]);

	const popupWorkingHolidayTrue = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<span style="color: black; margin-bottom: 30px;">Las edades para aplicar bajo el programa <b>“International Experience Canada”</b> podrían variar desde 18 hasta 30 o 35 años dependiendo el país de la aplicación. Le recomendamos comprobar si su edad cumple con los requisitos para aplicar a este visado a través del siguiente link: <a style="color: red; text-decoration: underline;"  target="_blank" href="https://www.canada.ca/en/immigration-refugees-citizenship/services/work-canada/iec/eligibility.html"> Requisitos de elegibilidad IEC</a></span><br></b>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}
	const popupWorkingHolidayFalse = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<span style="color: black; margin-bottom: 30px;"> Su edad esta fuera del rango para aplicar a esta visa. Las edades para aplicar bajo el programa <b>“International Experience Canada”</b> podrían variar desde 18 hasta 30 o 35 años dependiendo el país de la aplicación.</span>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupChildren20 = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<span style="color: black; margin-bottom: 30px;">Es posible que su hijo aplique como dependiente bajo el mismo núcleo familiar,</b> no obstante en base a que ya tiene la mayoria de edad <b>recomendamos altamente</b> presentar su propia carta de aceptación para estudiar un programa de idiomas o academico en una institución en Canadá, de lo contrario el riesgo de rechazo es muy alto.</span><br></b>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}

	const popupChildren21 = () => {
		Swal.fire({
			title: '<span style="color: #13C296;">Importante</span>',
			icon: 'info',
			html:
				'<span style="color: black; margin-bottom: 30px;">Su hijo no califica como hijo dependiente para ser incluida dentro de esta solicitud,</b> por lo que deberá aplicar por separado y llenar este formulario como solicitante principal. (contrato separado). Tenga en cuenta que no se incluirán servicios para él.</span><br></b>',
			showCloseButton: true,
			showCancelButton: false,
			focusConfirm: false,
			confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			},
			buttonsStyling: false,
		})
	}


	return (
		<>
			<BoxModal>
				<Label>Fecha de nacimiento:  </Label>
				<DatePicker
					className='custom-input'
					selected={birthday}
					onChange={(date: Date) => {
						handleChange('birthday', date)
						handleChange('birthdayFormatted', moment(date).format('YYYY-MM-DD'))
						setBirthday(date)
						//  validateShowCAQ()
						validateAgeForBiometrics(moment(date).format('YYYY-MM-DD'))
						validateAgeForWorkingHoliday(moment(date).format('YYYY-MM-DD'))
						validateAgeDependant(moment(date).format('YYYY-MM-DD'))
						calculateAge(date)
						setBirthday(date);
						localStorage.setItem("birthday" + currentMember.id, date.toString())


					}}
					dateFormat='yyyy-MM-dd'
					maxDate={moment.utc().toDate()}
					showYearDropdown
					showMonthDropdown
					dropdownMode='select'
					todayButton='Hoy'
					placeholderText='Selecciona una fecha'
					customInput={
						<Input
							className='custom-input'
							name='Fecha de nacimiento'
							value={moment(birthday).format('YYYY-MM-DD')}
							readOnly
							onChange={() => { }}
							onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
								e.preventDefault();
							}}
							onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
								e.preventDefault();
							}}
							onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
								e.preventDefault();
							}}

						/>
					}
					onChangeRaw={(e: React.FocusEvent<HTMLInputElement>) => {
						e.preventDefault();
					}}
				/>
				{age}
				{!currentMember.birthdayFormatted && displayErrors === true && <span className='text-tomato-500 text-xs italic' style={{ marginTop: '-11px', marginBottom: '11px' }}>
					La fecha de nacimiento es obligatoria
				</span>}

			</BoxModal>
		</>
	)
}

export default DateOfBirth

