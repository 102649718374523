import { Checkbox } from 'antd'
import React, {  useState } from 'react'
import { observer } from 'mobx-react'
import { Button } from 'Components'
import Swal from 'sweetalert2'
import './style/style.css'

interface IProps {
  setInfoDisclaimerStep: React.Dispatch<React.SetStateAction<number>>
}



const buttonStyle = {
  textDecoration: 'underline',
  fontSize: '1rem',
  color: '#0B76B7',
};

const circleStyle = {
  width: '20px',
  height: '20px',
  backgroundColor: '#0B76B7',
  color: 'white',
  borderRadius: '50%',
  fontSize: '1.2rem',
  verticalAlign: 'middle',
  marginLeft: '10px',
  display: 'inline-block',
  lineHeight: '25px', // Esto centrará el contenido verticalmente
};

const InfoDisclaimerTwo: React.FC<IProps> = ({ setInfoDisclaimerStep }) => {
  const [next, setNext] = useState(false)
  const [showWarning, setShowWarning] = useState(false);

  return (
    <>
      <div className='circle-container'>
        <div className='circle'>1</div>
        <p className='hidden sm:block' style={{ color: 'black', marginTop: '2px', marginRight: '10px' }}>PROPÓSITO</p>
        <div className='circle black-bg'>2</div>
        <p className='hidden sm:block' style={{ color: 'black', marginTop: '2px', marginRight: '10px' }}>DATOS MIGRATORIOS</p>
        <div className='circle black-bg'>3</div>
        <p className='hidden sm:block' style={{ color: 'black', marginTop: '2px', marginRight: '10px' }}>COTIZACION</p>
      </div>
      <div className='mt-5 mb-5 pl-5 pr-5' style={{ fontFamily: 'Lato, sans-serif', display: 'flex', justifyContent: 'center' }}>
        <div className='flex flex-col' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' , width:'100rem', gap:'30px' }} >
          <div className='step-two-container'>
            <h3 style={{ fontSize: '2rem', color: '#5B21B6', textAlign: 'left' }}></h3>
            <h3 style={{ fontSize: '1.3rem', textAlign: 'left' }}><b>Antes de continuar</b></h3>
          </div>
          <div className='step-two-description'>
            <p style={{ marginBottom: '20px' }}>Como responsable principal para la solicitud de contratación, es necesario cumplir con al menos uno de los siguientes requisitos:</p>
          <div>  <strong style={{ marginBottom: '20px' }}>1. Visita:</strong>  <p style={{ marginBottom: '20px' }}>Tiene una invitación de familiares o amigos o posee un itinerario detallado.</p></div>
           
          <div>  <strong style={{ marginBottom: '20px' }}>2. Estudios:</strong>  <p style={{ marginBottom: '20px' }}>Cuenta con una carta de aceptación de una institución educativa canadiense o es hijo dependiente de un estudiante o trabajador  <br /> en Canadá. Immiland puede gestionar el registro educativo si aún lo ha hecho.</p></div>
          <div>
  <strong style={{ marginBottom: '20px' }}>3. Trabajo: </strong>
  <p style={{ marginBottom: '20px' }}>Obtuvo una oferta de empleo canadiense o está próximo a graduarse de un programa elegible en Canadá para el PGWP o es pareja <br />  de un estudiante o trabajador en Canadá.</p>
</div>

<div>  <strong style={{ marginBottom: '20px' }}>4. Vivir permanentemente:</strong>  <p style={{ marginBottom: '20px' }}>Cumple con los requisitos de elegibilidad para obtener la residencia permanente.
</p></div>
<div>

  <p style={{ marginBottom: '20px' }}>Tome en cuenta que dentro de esta misma solicitud, sólo puede añadir dependientes directos (cónyuges/pareja de hecho e hijos  <br />  dependientes menores de 22 años al momento de enviar la aplicación).</p>
</div>
{/* <div>  <strong style={{ marginBottom: '20px' }}>5. Relación Familiar en Canadá:</strong>  <p style={{ marginBottom: '20px' }}>Ser pareja o hijo dependiente de un estudiante o trabajador o residente permanente en Canadá.</p></div>
<div>  <strong style={{ marginBottom: '20px' }}>6. Graduación Reciente:</strong>  <p style={{ marginBottom: '20px' }}>Haberse graduado recientemente de un programa o institución elegible, calificando para el Post-Graduation Work Permit (PGWP).</p></div>
<div>  <strong style={{ marginBottom: '20px' }}>7. Experiencia Internacional en Canadá: </strong>  <p style={{ marginBottom: '20px' }}>Ser elegible para participar en el programa International Experience Canada (IEC).</p></div>
<div>  <strong style={{ marginBottom: '20px' }}>8. Estatus válido en Canadá:</strong>  <p style={{ marginBottom: '20px' }}>tener un estatus legal válido y ser elegible para cambiarlo o extenderlo.</p></div>
<div>  <strong style={{ marginBottom: '20px' }}>9. Dependientes directos:</strong>  <p style={{ marginBottom: '20px' }}> Dentro de esta misma solicitud, sólo puede añadir dependientes directos (cónyuges/pareja de hecho e hijos dependientes menores de 22 años al momento de enviar la aplicación).</p></div> */}
            <div style={{ width: "100%", padding: '0px' }} className='blue-div'>
              <div style={{ backgroundColor: '#E9F9FF', borderLeft: '8px solid #0B76B7' }} className='blue-important'>
                <div className='text-importante' style={{ color: '#0B76B7', fontSize: '2rem', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img style={{ height:'50px'}} src="/icons/image-info.png" alt="Home" />
                  Importante
                </div >
                <div style={{}} className='blue-services'>
                  <div>
                  <p><b>Descargo de Responsabilidad: Cambios Potenciales en el Contrato:</b></p>
<p>Esta herramienta ofrece estimaciones rápidas basadas en la información proporcionada. Sin embargo, el contrato final puede cambiar debido a factores como la falta de elegibilidad, situaciones específicas o tarifas mandatorias no inicialmente identificadas. Agradecemos su comprensión y le instamos a revisar detenidamente el contrato antes de confirmar.</p>
                    <h1>
                {/*       <button type="button" onClick={popupDisplayLMIA}>
                        <span style={buttonStyle}>
                          ¿Qué hago en caso de familiares no inmediatos
                        </span>
                        <span style={circleStyle}>!</span>
                      </button> */}
                    </h1>
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: '#F9FAFB', width: '100%', borderRadius: '10px', marginTop: '20px' }}>
                <div style={{ fontSize: '1.1rem', margin: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '15px' }}>
                  <Checkbox onChange={(e) => {
                    setNext(e.target.checked);
                    // Oculta el mensaje de advertencia cuando el checkbox se marque o desmarque
                    setShowWarning(false);
                  }} value={next} />
                  <b>Entiendo y confirmo lo informado en el texto anterior</b>
                </div>
              </div>
              {showWarning && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  Debes dar click en la casilla de verificación para continuar.
                </div>
              )}
            </div>
          </div>
          <div className='button-container-disc2' style={{ display: 'flex', width: '100%', gap: '3rem', justifyContent: 'center' }}>
            <Button className='backbutton-disc2' onClick={() => {
              setInfoDisclaimerStep(1)
              scrollTo(0, 0)
            }}>Atras
            </Button>
            <Button className='startbutton-disc2'
              style={{
                background: next ? '' : '#E0E0E0',
                cursor: next ? '' : 'default',
              }}
              onClick={() => {
                if (next) {
                  setInfoDisclaimerStep(5);
                  scrollTo(0, 0);
                } else {
                  setShowWarning(true);
                }
              }}>
              Comenzar
              <img src="/icons/arrowwhite.png" alt="Home" style={{ width: '20px', height: '20px' }} />
            </Button>
          </div>
        </div>
      </div>

      <footer className='footer-style' >
        <div className='footer-style-continer'>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>¿Quienes somos?</strong></h3>
            <a href="https://www.immilandcanada.com/quienes-somos">Historia y Valores</a>
            <a href="https://www.immilandcanada.com/equipo-immiland">Nuestro Equipo</a>
            <a href="https://www.immilandcanada.com/testimonios">Testimonios</a>
            <a href="https://www.immilandcanada.com/tipos-de-visa/visa-de-estudio#">Preguntas Frecuentes</a>
            <a href="https://www.immilandcanada.com/terminos-y-condiciones"><strong>Términos y condiciones.</strong></a>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }} >
            <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Servicios</strong></h3>
            <a href="https://www.immilandcanada.com/migracion/inicio">Migración</a>
            <a href="https://www.immilandcanada.com/reclutamiento/inicio">Trabajo</a>
            <a href="https://www.immilandcanada.com/educacion/inicio" >Educacion</a>
            <a href="https://www.immilandcanada.com/traduccion/inicio">Traducciones</a>
            <a href="https://www.immilandcanada.com/establecimiento/inicio">Establecimiento</a>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Ubicación</strong></div>
            <div>
              <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4cc91bcf50dcd709:0x39dd1ca577308a39?source=g.page.share" >
                <div>2025 Rue Parthenais<br />suit 320<br />Montreal, Quebec,<br />Canadá</div>
              </a>
            </div>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>Blog</strong></h3>
            <div>
              <a href="https://www.immilandcanada.com/post/viajar-fuera-canada-mientras-estudio">
                <div>¿Puedo viajar fuera <br />de Canada mientras estudio?</div>
              </a>
            </div>
          </div>
          <div style={{
            width: '10rem',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div >
              <h3 style={{ color: 'white', marginBottom: '1rem', fontSize: '1.1rem' }}><strong>¡Sigamos en contacto!</strong></h3>
              <div ><span>Info@immilandcanada.com</span></div>
              <div style={{
                display: 'flex',
                gap: '0.5rem',
                paddingRight: '2rem',
                alignItems: 'center'
              }}>
                <a href="https://www.youtube.com/immiland">
                  <img src="https://img.icons8.com/ios-filled/25/ffffff/youtube-play.png" alt="" />
                </a>
                <a href="https://www.instagram.com/immilandcanada/">
                  <img src="https://img.icons8.com/windows/25/ffffff/instagram-new.png" alt="" />
                </a>
                <a href="https://www.facebook.com/immilandcanada">
                  <img src="https://img.icons8.com/ios-filled/25/ffffff/facebook--v1.png" alt="" />
                </a>
                <a href="https://www.tiktok.com/@immilandcanada">
                  <img src="https://img.icons8.com/ios-filled/25/ffffff/tiktok--v1.png" alt="" />
                </a>
                <a href="https://ca.linkedin.com/company/immiland-canada">
                  <img src="https://img.icons8.com/ios-glyphs/25/ffffff/linkedin-2--v1.png" alt="" />
                </a>
                <a href="https://twitter.com/immilandcanada">
                  <img src="https://img.icons8.com/material/25/ffffff/twitter--v2.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default observer(InfoDisclaimerTwo)
