/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "axios";
import { SetStateAction, useEffect, useState, Dispatch, useContext } from "react";
import { Spin } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import '../../../../../Components/Modal/modal.css';

import { RootStoreContext } from 'stores/rootStore'

import '../styles/index.css'
import envelopeImage from './envelope.png'
const API_URL = process.env.REACT_APP_API_URL;

const DOCUSIGN_URL = process.env.REACT_APP_DOCUSIGN_BACKEND_URL;


interface IProps {
	setVerificationDocusign: Dispatch<SetStateAction<boolean>>
}

const popupDisplayerror = () => {
	Swal.fire({
		title: '<span style="color: #13C296;">Importante</span>',
		icon: 'info',
		html:
			'<b>Su contrato no existe o escribio un numero de contrato erroneo, contactanos al correo retainer.agreement@immilandcanada.com para más información</p>',
		showCloseButton: true,
		showCancelButton: false,
		focusConfirm: false,
		confirmButtonText: 'Entendido!',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
	});
};

const popupDisplayInfo = () => {
	Swal.fire({
		title: '<span style="color: #13C296;">¿Cómo ver mi número de contrato?</span>',
		icon: 'info',
		html:
			`<a href="https://ibb.co/XSNgxqT"><img src=${envelopeImage} alt="envelope" border="0" max-width: 100% /></a>`,
		showCloseButton: true,
		showCancelButton: false,
		focusConfirm: false,
		confirmButtonText: 'Entendido',
			customClass: {
				confirmButton: 'btn-no-shadow',  // Estilo del botón "Cerrar"
			  },
			  buttonsStyling: false,
	});
};
const MyComponent: React.FC<IProps> = ({ setVerificationDocusign }) => {

	const [inputEnvelopeID, setInputEnvelopeID] = useState("");
	const [verificationResult, setVerificationResult] = useState("");
	const [verificationSuccess, setVerificationSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleCheckEnvelopeID = () => {
		if (!inputEnvelopeID) {
			setVerificationResult("Ingrese un envelopeID válido.");
			return;
		}
		setLoading(true);
		axios
			.get(`${DOCUSIGN_URL}/getPdf/${inputEnvelopeID}`)
			.then((response) => {
				if (response.data && response.data.errorCode) {
					popupDisplayerror();
					setVerificationResult("Su contrato no existe o escribio un numero de contrato erroneo.");
					setVerificationSuccess(false);
					setVerificationDocusign(false)
					currentMember.validContract = false
					handleChange('validContract', false)
				} else {
					setVerificationResult("Comprobacion exitosa.");
					setVerificationSuccess(true);
					setVerificationDocusign(true)
					currentMember.contractEnvelopeID = inputEnvelopeID
					handleChange('contractEnvelopeID', inputEnvelopeID)
					currentMember.validContract = true
					handleChange('validContract', true)

				}

			})
			.catch((error) => {
				setVerificationResult("Error en la verificacion.");
				setVerificationSuccess(false);
				setVerificationDocusign(false)
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const rootStore = useContext(RootStoreContext)
	const { principalUsed, currentMember, handleChangeFamilyMember, getVisaTypeFromSurvey } =
		rootStore.familyStore

	const handleChange = (
		target: string,
		value: string | number | Date | File | boolean | undefined
	) => {

		//const newFamily = {...family}
		const newFamily = Object.assign(currentMember, { [target]: value })

		handleChangeFamilyMember(newFamily)
	}
	useEffect(() => {
		currentMember.contractEnvelopeID ? setInputEnvelopeID(currentMember.contractEnvelopeID) : setInputEnvelopeID("");
	}, [currentMember.contractEnvelopeID]);
	const [showTooltip, setShowTooltip] = useState(false);
	return (
		<div>
			
			<label className="titleverify">
				<p>Ingrese el ID de su contrato previo y obtenga un  <i
          onClick={popupDisplayInfo}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          style={{ fontSize: '0.7rem', fontWeight: 'bold', color:'#22AD5C', cursor: 'pointer', position: 'relative' }}
        >
          30% de descuento.
          {showTooltip && (
            <span style={{
              position: 'absolute',
              top: '-20px',
              left: '0',
              backgroundColor: '#22AD5C',
              color: '#fff',
              padding: '5px',
              borderRadius: '3px',
              fontSize: '0.7rem',
			  width:'5rem',
            }}>
           click para más información!
            </span>
          )}
        </i>  </p>
				<input className='custom-input' type="text" onChange={(e) => {
					handleChange('contractEnvelopeID', e.target.value)
					currentMember.contractEnvelopeID = e.target.value
					setInputEnvelopeID(e.target.value)
				}}
					defaultValue={currentMember.contractEnvelopeID}
				/>
			</label>
			<div className="button-container">
				<button className="buttonverify" type="button" onClick={handleCheckEnvelopeID}>
					<p style={{color:'#0B76B7'}}>Verificar</p>
				</button>
				{loading && <Spin size="large" style={{ marginLeft: '10px',width: '10px',height: '10px',color: 'red' }} />}
			</div>

			{currentMember.validContract && <p> Comprobacion exitosa <CheckCircleOutlined style={{ color: 'green', marginLeft: '10px' }} />  </p>}
			{currentMember.validContract === false && <p> Su contrato no existe o escribio un numero de contrato erroneo, contactanos al correo
			</p>}
		</div>

	);
}

export default MyComponent;
